import React from 'react'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation from '../../components/Infos/InfosNavigation'
import { StaticImage } from 'gatsby-plugin-image'
import ColorButton from '../../components/Ui/ColorButton'
import Collapsible from '../../components/Ui/Collapsible'


const ZoneDeServices = () => {
  return(
    <div className='w-full px-9'>

      {/* TOP IMAGE */}
      <div className="py-6">
        <StaticImage src="../../images/infos/zone_de_service.jpg" alt="Image représentant la zone de service" imgClassName="rounded-3xl"/>
      </div>

      {/* HIGHLIGHTED TEXT */}
      <div>
        <h3 className='text-center mx-6 !mb-12 !mt-6'>Située près du Parvis, cette zone permettra aux festivalier·ères de trouver plusieurs commodités.</h3>
      </div>

      {/* Le Disquaire présenté */}
      <div>
        <h2>Le Disquaire & la boutique</h2>
        <p className='!pb-0'>Viens te procurer un vinyle de ton artiste préféré.e au disquaire! Dans la boutique, tu pourras aussi te procurer des articles à l’effigie du Festif!, comme nos pièces de vêtements revalorisés, nos gourdes réutilisables, notre sérigraphie, la sauce piquante de La Pimenterie, le mélange de Café Charlevoix aux couleurs du Festif!.</p>
        <br/>
        <div className='!mx-6 mb-6'>
          <b>Mercredi 16 juillet: 16h à 21h</b>
          <br/>
          <b>Jeudi 17 juillet : 12h à 21h</b>
          <br/>
          <b>Vendredi 18 juillet : 10h à 21h</b>
          <br/>
          <b>Samedi 19 juillet : 10h à 21h</b>
          <br/>
          <b>Dimanche 20 juillet : 10h à 15h</b>
        </div>
      </div>

      {/* KIOSQUE D'INFORMATION */}
      <div>
        <h2>Kiosque d'information</h2>
        <p className='!pb-0'>Tu as une question pendant le festival? Viens nous voir au kiosque d’info du festival, situé près du Parvis du Festif! dans le parc localisé dans le stationnement de l’Église du 16 au 20 juillet 2025.</p>
        <br/>
        <div className='!mx-6 mb-6'>
          <b>Mercredi 16 juillet : 16h à 21h</b>
          <br/>
          <b>Jeudi 17 juillet : 12h à 21h</b>
          <br/>
          <b>Vendredi 18 juillet : 10h à 21h</b>
          <br/>
          <b>Samedi 19 juillet : 10h à 21h</b>
          <br/>
          <b>Dimanche 20 juillet : 10h à 15h</b>
        </div>
      </div>

      <div>
        <h2>Borne de recharge Bell</h2>
        <p>Besoin de charger ton téléphone pour ne manquer aucune notification ? Aucun problème, Bell t'installe une borne de recharge au Parvis!</p>
      </div>

      <div>
        <h2>Zone de réconfort</h2>
        <p><ColorButton href="/infos/zonedereconfort#info_content" text="Zone de réconfort" className="my-4 mb-6" /></p>
      </div>

      <div>
        <h2>Développement durable</h2>

        <h4 className='!py-0'>Kiosque de développement durable RECYC-QUÉBEC</h4>
        <p>Plusieurs organismes, acteur·ices et entreprises écoresponsables qui agissent comme leaders en lien avec le développement durable se réunissent pour te jaser d’idées, de stratégies et d’actions qui contribuent au bien-être de notre planète et de notre communauté. Services pratiques, ateliers, renseignements pertinents et marché public y seront toute la fin de semaine, passe nous voir!</p>

        <div className='text-center mt-2 mb-6'>
          <StaticImage className="mt-4 w-[200px]" imgClassName="!object-contain object-left" src="../../images/partenaires/recyc-quebec-logo-black.png" alt="Logo Recyc-Québec" placeholder="none"/>
        </div>

        <div className="max-w-[800px] text-center flex mb-8 mx-auto">
          <StaticImage src="../../images/conference.jpg" alt="Photo des conféférences" className='rounded-3xl'/>
        </div>
      </div>
    </div>
  )
}

const Services = () => {
  const metaTitle = 'Services | Le Festif! de Baie-Saint-Paul'
  const metaDescription = ''
  const metaKeywords = 'festival, musique, évènement, spectacles, québec, charlevoix, baie-saint-paul'

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader
        title="Services"
      />
      <div className="container content pb-12">

        {/* TOP TEXT */}
        <div className='text-center mb-6'>
            <h4 className='!font-obviously !font-regular !text-xl'>Au Festif!, on sait que l’expérience globale compte pour beaucoup dans le niveau de satisfaction et que celui-ci ne se mesure pas qu’avec les shows auxquels tu auras assisté, mais aussi avec l’accessibilité des services et la proximité des zones de soins sur le site. Parce que tes besoins, ton confort et ton bien-être font aussi partie du spectacle!</h4>
        </div>

        <Collapsible title="Zone de services" >
          <ZoneDeServices />
        </Collapsible>

        <Collapsible title="Zones bébés" >
          <div className='px-12'>
            <div className="py-6">
              <StaticImage src="../../images/infos/zone_bebes.jpg" alt="Photo d'un adulte tenant un bébé" imgClassName="rounded-3xl"/>
            </div>
            <p className='!px-0'>Les zones bébés, situées au Camping du Fleuve et dans l’Espace Famille, sont spécialement conçues pour toi, parent, qui souhaite allaiter, donner le biberon, changer la couche ou simplement prendre une pause.</p>
            <ColorButton text="Voir la carte" href="/infos/carte#info_content" className="mt-4 mb-9" />
          </div>
        </Collapsible>

        <Collapsible title="Zones de soin Jean Coutu" >
          <div className='px-12'>
            <div className="py-6">
              <StaticImage src="../../images/infos/zone_de_soins.jpg" alt="Photo de tentes dans un camping temporaire" imgClassName="rounded-3xl"/>
            </div>
            <p className='!px-0'>Grâce à la pharmacie Jean Coutu Simon Rebillard, tu trouveras sur place des zones de soins où tu pourras te procurer tout ce que tu auras pu oublier à la maison. Que ce soit des médicaments, des pansements ou même des articles de soin personnels, l’équipe sera là pour te dépanner!</p>
            <ColorButton text="Voir la carte" href="/infos/carte#info_content" className="my-4" />
            <a href="https://www.jeancoutu.com/localisateur-succursale/baie-saint-paul-975-boulevard-monseigneur-de-laval-bureau-101-g3z-2w3/186/" target='_blank' rel="noreferrer noopener" className='w-full justify-center flex'>
              <StaticImage src="../../images/partenaires/Pharmacie-Simon-Rebillard.png" alt="Logo Parmacie Simon Rebillard" className="w-[200px]" />
            </a>
          </div>
        </Collapsible>

      </div>
    </Wolflayout>
  )
}

export default Services
